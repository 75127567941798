import * as React from 'react'
import _ from 'lodash'
import seedrandom from 'seedrandom'
import shuffle from 'shuffle-array'
import { RouteComponentProps } from '@reach/router'
import IndexLayout from '../../components/layout'
import Header from '../../components/Header'
import { injectIntl, InjectedIntlProps } from 'react-intl'
import Helmet from 'react-helmet'
import PageHeader from '../../components/PageHeader'
import styled, { ThemeProvider } from 'styled-components'
import {
  Teaser,
  CTA,
  media,
  themes,
  Container,
  Row,
  Col,
  gridLgSpacing,
  ImageBar,
  TwoColumn,
  TeaserImage,
  // Spacer,
  FaqSection,
} from 'cc-ui-components'
import { bgColorMapping } from '../../styles/config'
import PageDefault from '../../components/Page'
import Timeline from '../../components/page/Timeline'
import { Title } from '../../components/page/Title'
import ContactForm from '../../components/page/ContactForm'
import { Grid } from '../../components/page/Grid'
// import * as SegmentAnalytics from '../../lib/segment'
// import MainNavigation, { NavItem } from '../../components/MainNavigation'
// import { graphql, StaticQuery } from 'gatsby'
import { Links } from '../../components/page/Links'
import { Slider } from '../../components/page/Slider'

interface ProjectProps extends RouteComponentProps {
  pageContext: {
    language: string
    slug: string
    prefix: string
    skill: string
    jobTitlePlural: string
    jobTitleSingular: string
    pageTitle: string
  }
}

const Page = styled(PageDefault)`
  ${media.sm`
    & > div {
      & > div > div {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
  `};
`

const SectionCol = styled(Col)`
  & > * {
    margin-top: 80px;
    &:first-child {
      margin-top: 0;
    }
  }
  ${media.sm`
   & > * {
      margin-top: 30px;
    }
    padding: 0 20px;
  `};
`

const Margin = styled.div`
  margin-top: ${props => props.top || 0};
  margin-bottom: ${props => props.bottom || 0};
`

// const Menu = styled.div`
//   margin: 26px auto 46px;
// `

const developers = [
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/ZXyhen80hB0dctJjCmMFP/bb2657f1482375fa0f9109ed64de41e5/Dejan_Jurkovic__.jpg',
    name: 'Dejan Jurkovic',
    jobStringId: 'projects.grid.title',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/36M0agGsWHNFiLwTJoVDFG/0d1e9f6306fd0bfc9da294677eeaa9f1/Harry_Parsons.jpg',
    name: 'Harry Parsons',
    jobStringId: 'projects.grid.titleSenior',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/4R8wfDwcFF2hUgM1QCMuYm/337c0505febe24ac3d6930f5f8b1cb03/Jan_Beich.jpg',
    name: 'Jan Beich',
    jobStringId: 'projects.grid.titleSenior',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/24dsyPvayLsm03saQwVYKP/6aea7e915339e392984a9fa5306b1d8a/Marjan_Novak.jpg',
    name: 'Marjan Novak',
    jobStringId: 'projects.grid.title',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/6tlRutyYenLZeN9P5dGGAr/106100f9372fcbda67563ea287126f28/Milena_Slatinska__.jpg',
    name: 'Milena Slatinska',
    jobStringId: 'projects.grid.title',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/aPDutzKmRSNHitCN30hrU/505863469ff4e04c2743cbeebdbce357/Nadir_Samaha.jpg',
    name: 'Nadir Samaha',
    jobStringId: 'projects.grid.titleSenior',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/7FodMR3AyaTqvgRk2nLw1R/5d7b38253be30a259e9c77a2183d6626/Aatik_Patel.jpg',
    name: 'Aatik Patel',
    jobStringId: 'projects.grid.titleSenior',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/61x1RCPdd1HgWozOFkVtZv/379b4b5c9543ce6f04539a95309b039d/Anastasia_Sorokina.jpg',
    name: 'Anastasia Sorokina',
    jobStringId: 'projects.grid.title',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/5gzZ970cRe3IWrqW8MARWZ/8f06dbec41017533df608ec1ff8039c5/Benjamin_Agostini.jpg',
    name: 'Benjamin Agostini',
    jobStringId: 'projects.grid.title',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/4dF1ARAS6LfDrhBIktS2wv/8577797c4a75731bfb51fe4e5f0f8b29/Daniel_Bernhard.jpg',
    name: 'Daniel Bernhard',
    jobStringId: 'projects.grid.titleSenior',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/2cdL6LC9esvFDSwI7COV2/f7f539473ac5491ef50c3be116ae13ab/Dirk_Naumann.jpg',
    name: 'Dirk Naumann',
    jobStringId: 'projects.grid.titleSenior',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/2YBX2zBEumjqyxtuPIeVlr/b5447f9fa8a34cd419c1cef87e32e91f/Lucas_Vuong.jpg',
    name: 'Lucas Vuong',
    jobStringId: 'projects.grid.title',
  },
  {
    image:
      'https://images.ctfassets.net/roje8i2i5yi1/5bQ0tXJp0SNzfqdrwpOkjp/eea75bb2cf3c5408c584a1fd39539e50/Rita_Tello.jpg',
    name: 'Rita Tello',
    jobStringId: 'projects.grid.titleSenior',
  },
]

function getDevelopers(seed: string) {
  const rng = seedrandom(seed)
  return shuffle.pick(developers, { rng, picks: 6 })
}

// TODO: PAGE TITLE
class Project extends React.Component<ProjectProps & InjectedIntlProps> {
  /*componentDidMount(): void {
    const { pageContext } = this.props
    const { pageTitle } = pageContext
    requestAnimationFrame(() => {
      SegmentAnalytics.page(`Dynamic Webpage / A / ${pageTitle}`, {
        version: 'a2',
      })
    })
  }*/

  public render() {
    const { intl } = this.props
    const {
      language,
      slug,
      prefix,
      article,
      skill,
      jobTitlePlural,
      jobTitleSingular,
      pageTitle,
    } = this.props.pageContext

    const description = intl.formatMessage({
      id: 'projects.meta.description',
    })

    return (
      <React.Fragment>
        <Helmet htmlAttributes={{ lang: language }}>
          <title>CodeControl | {pageTitle} </title>
          <meta name="description" content={description} />
          <meta property="og:title" content={`CodeControl | ${pageTitle}`} />
          <meta property="og:description" content={description} />
          <meta property="og:locale" content={language} />
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <Header hideNav={false} hideMobileNav={false} showMobileCTA={true} />
        <ThemeProvider theme={themes.light}>
          <PageHeader
            alignment="left"
            alignmentVertical="center"
            teaser={
              <Teaser
                title={intl.formatMessage(
                  { id: 'projects.teaser1.title' },
                  {
                    prefix,
                    skill,
                    jobTitlePlural,
                  }
                )}
                copy={{
                  childMarkdownRemark: {
                    html: `<p>${intl.formatMessage(
                      {
                        id: 'projects.teaser1.copy',
                      },
                      {
                        article,
                        skill,
                        jobTitleSingular,
                      }
                    )}</p>`,
                  },
                }}
                links={
                  <CTA
                    title={intl.formatMessage({
                      id: 'projects.cta.getInTouch',
                    })}
                    internalLink={intl.formatMessage({
                      id: 'path.get-in-touch',
                    })}
                    type={'flat'}
                  />
                }
                linkSubtext={{
                  childMarkdownRemark: {
                    html: intl.formatMessage({
                      id: 'projects.teaser1.linkSubtext',
                    }),
                  },
                }}
              />
            }
            imageOverlayOpacity={0.75}
            imageOverlay="//images.ctfassets.net/6caxi523i64u/66Pk1JEj8h1zI59ynCDO7b/e806944426f3793b69c412933c2786b2/developer2.png?w=1170&q=70&fm=jpg"
          />
        </ThemeProvider>
        <Page>
          <ThemeProvider theme={themes.regular}>
            <Container
              padingsVertical={['90px', '90px']}
              backgroundColor={bgColorMapping.regular}
            >
              <Row>
                <SectionCol sm={12} md={12} lg={gridLgSpacing} center>
                  <Teaser
                    title={intl.formatMessage(
                      {
                        id: 'projects.teaser2.title',
                      },
                      {
                        skill,
                      }
                    )}
                    subtitle={intl.formatMessage({
                      id: 'projects.teaser2.subtitle',
                    })}
                    alignment="center"
                  />
                  <Timeline
                    showStepNumbers={false}
                    steps={[
                      {
                        title: intl.formatMessage({
                          id: 'projects.timeline1.step1.title',
                        }),
                        description: {
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'projects.timeline1.step1.description',
                            }),
                          },
                        },
                      },
                      {
                        title: intl.formatMessage({
                          id: 'projects.timeline1.step2.title',
                        }),
                        description: {
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'projects.timeline1.step2.description',
                            }),
                          },
                        },
                      },
                      {
                        title: intl.formatMessage({
                          id: 'projects.timeline1.step3.title',
                        }),
                        description: {
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'projects.timeline1.step3.description',
                            }),
                          },
                        },
                      },
                    ]}
                  />
                  <Margin bottom={'120px'}>
                    <Links
                      elements={[
                        {
                          title: intl.formatMessage({
                            id: 'projects.cta.getInTouch',
                          }),
                          internalLink: intl.formatMessage({
                            id: 'path.get-in-touch',
                          }),
                          key: 'cta1',
                          type: 'flat',
                        },
                      ]}
                    />
                  </Margin>
                  <TwoColumn
                    elements={[
                      <Teaser
                        key="teaser3"
                        title={intl.formatMessage({
                          id: 'projects.teaser3.title',
                        })}
                        subtitle={intl.formatMessage({
                          id: 'projects.teaser3.subtitle',
                        })}
                        copy={{
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'projects.teaser3.copy',
                            }),
                          },
                        }}
                        links={
                          <CTA
                            title={intl.formatMessage({
                              id: 'projects.cta.startHiring',
                            })}
                            internalLink={intl.formatMessage({
                              id: 'path.get-in-touch',
                            })}
                            type={'flat'}
                          />
                        }
                      />,
                      <Grid
                        key="grid-1"
                        gridElements={_.map(
                          getDevelopers(`${language}/${slug}`),
                          developer => ({
                            title: intl.formatMessage(
                              { id: developer.jobStringId },
                              {
                                prefix,
                                skill,
                                jobTitleSingular,
                              }
                            ),
                            profilePicture: developer.image,
                            fullName: developer.name,
                          })
                        )}
                      />,
                    ]}
                    type="50/50"
                  />
                </SectionCol>
              </Row>
            </Container>
          </ThemeProvider>

          <ThemeProvider theme={themes.light}>
            <Container
              padingsVertical={['90px', '90px']}
              backgroundColor={bgColorMapping.light}
            >
              <Row>
                <SectionCol sm={12} md={12} lg={gridLgSpacing} center>
                  <Teaser
                    alignment="center"
                    title={intl.formatMessage({
                      id: 'projects.teaser4.title',
                    })}
                    subtitle={intl.formatMessage({
                      id: 'projects.teaser4.subtitle',
                    })}
                  />
                  <ImageBar
                    images={[
                      '//images.ctfassets.net/6caxi523i64u/1WcPsYw7fyYwgWsy4cMoaI/554ea6e2d3b2eb8184e4f2b3fb337859/8hc.svg',
                      '//images.ctfassets.net/6caxi523i64u/3l65rjYUn6akqK64qYw6ws/65db4b11c401c2b53ce645f546640c9f/8hd.svg',
                      '//images.ctfassets.net/6caxi523i64u/7chIWtMS6kuSwecUYW6ua4/a7a837e466d421c9d16b8f0eabc175c2/8gs.svg',
                      '//images.ctfassets.net/6caxi523i64u/PT6rQjup2w4yMYe68s0QU/4387185b5fb469dbd690d2db436c9601/8gW.svg',
                      '//images.ctfassets.net/6caxi523i64u/4k0VJOHilyeeGykgEY2acw/6c41aea4330eb5c2c23b973251f6cf1e/8hi.svg',
                      '//images.ctfassets.net/6caxi523i64u/18EP13FS9Q4e8uIggEcGeS/0341b400a2d2f5d591fd61ea5a896074/V6KKtEx.png?w=300&q=70&fm=jpg',
                    ]}
                  />
                </SectionCol>
              </Row>
            </Container>
          </ThemeProvider>

          <ThemeProvider theme={themes.regular}>
            <Container
              padingsVertical={['90px', '90px']}
              backgroundColor={bgColorMapping.regular}
            >
              <Row>
                <SectionCol sm={12} md={12} lg={gridLgSpacing} center>
                  <Teaser
                    title={intl.formatMessage({
                      id: 'projects.teaser5.title',
                    })}
                    subtitle={intl.formatMessage({
                      id: 'projects.teaser5.subtitle',
                    })}
                    alignment="vertical"
                  />
                  <Slider
                    slides={[
                      {
                        element: [
                          {
                            __typename: 'ContentfulModuleTwoColumnRow',
                            elements: [
                              {
                                image:
                                  '//images.ctfassets.net/6caxi523i64u/CyQPWpI1gGIz3b8lhadRC/562ee47853d6a9e60619c30413da0f3c/illustration-process-scoping.svg',
                              },
                              {
                                title: intl.formatMessage({
                                  id: 'projects.slider1.slide1.title',
                                }),
                                subtitle: intl.formatMessage({
                                  id: 'projects.slider1.slide1.subtitle',
                                }),
                                copy: {
                                  childMarkdownRemark: {
                                    html: intl.formatMessage({
                                      id: 'projects.slider1.slide1.copy',
                                    }),
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        element: [
                          {
                            __typename: 'ContentfulModuleTwoColumnRow',
                            elements: [
                              {
                                image:
                                  '//images.ctfassets.net/6caxi523i64u/2arRDLZ35DGaQ69NubKQfc/04aa5ce9753d300c5a898efc1dc710b2/project-delivery.svg',
                              },
                              {
                                title: intl.formatMessage({
                                  id: 'projects.slider1.slide2.title',
                                }),
                                subtitle: intl.formatMessage({
                                  id: 'projects.slider1.slide2.subtitle',
                                }),
                                copy: {
                                  childMarkdownRemark: {
                                    html: intl.formatMessage({
                                      id: 'projects.slider1.slide2.copy',
                                    }),
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        element: [
                          {
                            __typename: 'ContentfulModuleTwoColumnRow',
                            elements: [
                              {
                                image:
                                  '//images.ctfassets.net/6caxi523i64u/5hFlh2wt1Havf8DGlGXlgT/7639b360301ff3b9b896beb06e534347/group-2.svg',
                              },
                              {
                                title: intl.formatMessage({
                                  id: 'projects.slider1.slide3.title',
                                }),
                                subtitle: intl.formatMessage({
                                  id: 'projects.slider1.slide3.subtitle',
                                }),
                                copy: {
                                  childMarkdownRemark: {
                                    html: intl.formatMessage({
                                      id: 'projects.slider1.slide3.copy',
                                    }),
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        element: [
                          {
                            __typename: 'ContentfulModuleTwoColumnRow',
                            elements: [
                              {
                                image:
                                  '//images.ctfassets.net/6caxi523i64u/2arRDLZ35DGaQ69NubKQfc/04aa5ce9753d300c5a898efc1dc710b2/project-delivery.svg',
                              },
                              {
                                title: intl.formatMessage({
                                  id: 'projects.slider1.slide4.title',
                                }),
                                subtitle: intl.formatMessage({
                                  id: 'projects.slider1.slide4.subtitle',
                                }),
                                copy: {
                                  childMarkdownRemark: {
                                    html: intl.formatMessage({
                                      id: 'projects.slider1.slide4.copy',
                                    }),
                                  },
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ]}
                    displayNavDots={true}
                  />
                  <Links
                    elements={[
                      {
                        title: intl.formatMessage({
                          id: 'projects.cta.getInTouch',
                        }),
                        internalLink: intl.formatMessage({
                          id: 'path.get-in-touch',
                        }),
                        key: 'cta2',
                        type: 'flat',
                      },
                    ]}
                  />
                </SectionCol>
              </Row>
            </Container>
          </ThemeProvider>

          <ThemeProvider theme={themes.light}>
            <Container
              padingsVertical={['90px', '90px']}
              backgroundColor={bgColorMapping.light}
            >
              <Row>
                <SectionCol sm={12} md={12} lg={gridLgSpacing} center>
                  <Teaser
                    title={intl.formatMessage({
                      id: 'projects.teaser6.title',
                    })}
                    subtitle={intl.formatMessage({
                      id: 'projects.teaser6.subtitle',
                    })}
                    alignment="center"
                  />
                  <Slider
                    slides={[
                      {
                        element: [
                          {
                            __typename: 'ContentfulClient',
                            name: intl.formatMessage({
                              id: 'projects.slider2.slide1.name',
                            }),
                            logo:
                              '//images.ctfassets.net/6caxi523i64u/3OZtyZJOpisGSqs8mCQkQ4/da79c54bf2dcddeef3ff1e2ec2ea5495/LH_Inno.png?w=300&q=70&fm=jpg',
                            quote: {
                              quote: {
                                childMarkdownRemark: {
                                  html: intl.formatMessage({
                                    id: 'projects.slider2.slide1.quote',
                                  }),
                                },
                              },
                              quoteGiver: intl.formatMessage({
                                id: 'projects.slider2.slide1.quoteGiver',
                              }),
                            },
                          },
                        ],
                      },
                      {
                        element: [
                          {
                            __typename: 'ContentfulClient',
                            name: intl.formatMessage({
                              id: 'projects.slider2.slide2.name',
                            }),
                            logo:
                              '//images.ctfassets.net/6caxi523i64u/7wTuidQLPqMeWceiAaoOGy/881b6931962caf424060be60fddd6249/Coya-Logo-main.png?w=300&q=70&fm=jpg',
                            quote: {
                              quote: {
                                childMarkdownRemark: {
                                  html: intl.formatMessage({
                                    id: 'projects.slider2.slide2.quote',
                                  }),
                                },
                              },
                              quoteGiver: intl.formatMessage({
                                id: 'projects.slider2.slide2.quoteGiver',
                              }),
                            },
                          },
                        ],
                      },
                      {
                        element: [
                          {
                            __typename: 'ContentfulClient',
                            name: intl.formatMessage({
                              id: 'projects.slider2.slide3.name',
                            }),
                            logo:
                              '//images.ctfassets.net/6caxi523i64u/5C0mlhRSCcQYIUoEICWiE/e98c000173338a3ba16aca15ee822af3/8hc.svg',
                            quote: {
                              quote: {
                                childMarkdownRemark: {
                                  html: intl.formatMessage({
                                    id: 'projects.slider2.slide3.quote',
                                  }),
                                },
                              },
                              quoteGiver: intl.formatMessage({
                                id: 'projects.slider2.slide3.quoteGiver',
                              }),
                            },
                          },
                        ],
                      },
                      {
                        element: [
                          {
                            __typename: 'ContentfulClient',
                            name: intl.formatMessage({
                              id: 'projects.slider2.slide4.name',
                            }),
                            logo:
                              '//images.ctfassets.net/6caxi523i64u/5INtKr25j2ACsi2sKyQs8m/b8f260e8f0c38a87312c4292f3ae2b05/dm_logo.svg',
                            quote: {
                              quote: {
                                childMarkdownRemark: {
                                  html: intl.formatMessage({
                                    id: 'projects.slider2.slide4.quote',
                                  }),
                                },
                              },
                              quoteGiver: intl.formatMessage({
                                id: 'projects.slider2.slide4.quoteGiver',
                              }),
                            },
                          },
                        ],
                      },
                    ]}
                    displayNavDots={true}
                  />
                </SectionCol>
              </Row>
            </Container>
          </ThemeProvider>

          <ThemeProvider theme={themes.regular}>
            <Container
              padingsVertical={['90px', '90px']}
              backgroundColor={bgColorMapping.regular}
            >
              <Row>
                <SectionCol sm={12} md={12} lg={gridLgSpacing} center>
                  <TwoColumn
                    elements={[
                      <TeaserImage
                        key={'teaser7image'}
                        image="//images.ctfassets.net/6caxi523i64u/1hh3zYL2e6QWEQ8yqWCqcq/ac69ec3f15cddba34dafa3d61da5cec6/Artboard_1.svg"
                        type={'flat'}
                        revertTeaserImageOrientation={false}
                      />,
                      <Teaser
                        key="teaser7"
                        title={intl.formatMessage({
                          id: 'projects.teaser7.title',
                        })}
                        subtitle={intl.formatMessage({
                          id: 'projects.teaser7.subtitle',
                        })}
                        copy={{
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'projects.teaser7.copy',
                            }),
                          },
                        }}
                        links={
                          <CTA
                            title={intl.formatMessage({
                              id: 'projects.cta.scheduleCall',
                            })}
                            internalLink={intl.formatMessage({
                              id: 'path.get-in-touch',
                            })}
                            type={'regular'}
                          />
                        }
                      />,
                    ]}
                    type="50/50"
                  />
                </SectionCol>
              </Row>
            </Container>
          </ThemeProvider>

          <ThemeProvider theme={themes.light}>
            <Container
              padingsVertical={['90px', '90px']}
              backgroundColor={bgColorMapping.light}
            >
              <Row>
                <SectionCol sm={12} md={12} lg={gridLgSpacing} center>
                  <Teaser
                    title={intl.formatMessage({
                      id: 'projects.teaser8.title',
                    })}
                    subtitle={intl.formatMessage({
                      id: 'projects.teaser8.subtitle',
                    })}
                    alignment="center"
                    links={
                      <CTA
                        title={intl.formatMessage({
                          id: 'projects.cta.getAQuote',
                        })}
                        internalLink={intl.formatMessage({
                          id: 'path.get-in-touch',
                        })}
                        key="cta3"
                        type="flat"
                      />
                    }
                  />
                </SectionCol>
              </Row>
            </Container>
          </ThemeProvider>

          <ThemeProvider theme={themes.regular}>
            <Container
              padingsVertical={['90px', '90px']}
              backgroundColor={bgColorMapping.regular}
            >
              <Row>
                <SectionCol sm={12} md={12} lg={gridLgSpacing} center>
                  <Title
                    title={intl.formatMessage({
                      id: 'projects.faq.title',
                    })}
                    subtitle={intl.formatMessage({
                      id: 'projects.faq.subtitle',
                    })}
                    alignment="center"
                  />
                  <FaqSection
                    elements={[
                      {
                        question: intl.formatMessage({
                          id: 'projects.faq.q1.title',
                        }),
                        answer: {
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'projects.faq.q1.answer',
                            }),
                          },
                        },
                      },
                      {
                        question: intl.formatMessage({
                          id: 'projects.faq.q2.title',
                        }),
                        answer: {
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'projects.faq.q2.answer',
                            }),
                          },
                        },
                      },
                      {
                        question: intl.formatMessage({
                          id: 'projects.faq.q3.title',
                        }),
                        answer: {
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'projects.faq.q3.answer',
                            }),
                          },
                        },
                      },
                      {
                        question: intl.formatMessage({
                          id: 'projects.faq.q4.title',
                        }),
                        answer: {
                          childMarkdownRemark: {
                            html: intl.formatMessage({
                              id: 'projects.faq.q4.answer',
                            }),
                          },
                        },
                      },
                    ]}
                  />
                </SectionCol>
              </Row>
            </Container>
          </ThemeProvider>

          <ThemeProvider theme={themes.dark}>
            <Container
              padingsVertical={['90px', '90px']}
              backgroundColor={bgColorMapping.dark}
            >
              <Row>
                <SectionCol sm={12} md={12} lg={gridLgSpacing} center>
                  <ContactForm
                    title={intl.formatMessage({
                      id: 'projects.contact.title',
                    })}
                  />
                </SectionCol>
              </Row>
            </Container>
          </ThemeProvider>
          {/*
          <Menu>
            <StaticQuery
              query={graphql`
                query {
                  allContentfulModuleNavigation {
                    edges {
                      node {
                        title
                        node_locale
                        navigationElements {
                          ...navigationElements
                        }
                      }
                    }
                  }
                }
              `}
              render={data => {
                let navItems = []
                const navigation: any =
                  data.allContentfulModuleNavigation.edges.filter(
                    (edge: any) =>
                      edge.node.title === 'Main Navigation' &&
                      edge.node.node_locale.startsWith(intl.locale)
                  )[0].node || null
                if (navigation) {
                  navItems = navigation.navigationElements.map(
                    (element: any) => {
                      const path =
                        element.targetPath || _.get(element, 'targetPage.slug')
                      return {
                        text: element.title,
                        link: `/${intl.locale}/${path}`.replace('//', '/'),
                      }
                    }
                  )
                }

                return <MainNavigation items={navItems} />
              }}
            />
          </Menu>
           */}
        </Page>
      </React.Fragment>
    )
  }
}

const ProjectIntl = injectIntl(Project)

const ProjectWrapper = (props: ProjectProps) => (
  <IndexLayout location={props.location} hideFooter={true}>
    <ProjectIntl {...props} />
  </IndexLayout>
)

export default ProjectWrapper
